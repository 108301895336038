

export const contactslide = {
    id: 'contact1',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'For any questions or inquires please leave your name, email and message below.',
    description: '',
    buttonLabel: 'Submit',
    imgStart: false,
    dark: false,
    primary: false,
    darkText: true,
    rowline: 'hey',

};
